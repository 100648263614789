.App,
.App input,
.App button {
  font-size: 1.2rem !important;
  font-family: "serif", "cwTeXKai", "DFKai-sb", "標楷體";
}
.App .alert {
  font-size: 1.5rem !important;
}
.App .alert-success {
  color: #0b2211;
}

label:not(.form-check-label) {
  font-weight: 500;
}

:root {
  --accent-dark: rgb(54, 83, 229);
  --accent-light: rgb(93, 117, 239);
}

a {
  color: var(--accent-dark);
  text-decoration: none;
}

.FormError {
  color: red;
  margin-top: 5px;
}

.disclaimer {
  color: var(--accent-light) !important;
  font-size: 1rem;
  font-weight: 500;
}
