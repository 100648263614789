.DebugNav {
  background-color: #dddddd;
  margin-top: 10px;
}
.DebugNav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.DebugNav li {
  display: inline;
  margin: 0 1rem;
}
.DebugNav span {
  font: smaller;
}
